<template>
    <div class="mini_game_bet">
        <div class="mini_game">
            <div class="m1">
                <div class="mini_fram">
                    <leisure-game-ifram-view>
<!--                        <iframe id="bet365-gamefram" scrolling="no" frameborder="0"
                                src="https://vet3.net/video?q=high&player=1&autostart=true&title=Britannia Way"></iframe>-->
                      <iframe id="bet365-gamefram" scrolling="no" frameborder="0"
                              src="https://b3.nust365.com/ios/?vn=7"></iframe>

                    </leisure-game-ifram-view>
                </div>
            </div>

            <div class="m2">
                <div class="game_tab">
                    <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
                    <leisure-timer-comp
                            ref="ltimer"
                            :leisure-game-info="leisureGameInfo"
                            @timerStop="timerStop" @refreshTimer="refreshTimer">
                        <span slot="title" class="text-orange"><i class="fa fa-star"></i>브리타니아 웨이</span>
                    </leisure-timer-comp>

                    <!--선택부분-->
                    <div class="mini_bet_box">
                        <div class="mg" style="justify-content: flex-start;margin-right: 2px">
                            <span class="t">우승번호</span>
                            <div class="mg_btn b3 mt5 ml-2" v-for="(item) in horseraceGameList"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_HORCERACE_2_NORMAL && selectedInfo.selectedWay === item.key}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_HORCERACE_2_NORMAL,horseraceGame.id,item.key, item.val, horseraceConfig,item.key+'번')">
                                <div style="color: #f0f0f0">
                                    {{item.name}}
                                </div>
                                <div class="r">
                                    <span class="n text_color01">{{item.key}}번</span>
                                    <span class="b text-blue">{{item.val}}</span>
                                </div>
                            </div>
                        </div>


                    </div>

                    <!--파워볼 베팅카트-->
                    <leisure-bet-cart-comp
                            ref="betCart" :kind="kind"
                            :selected-info="selectedInfo">
                        브리타니아 웨이
                    </leisure-bet-cart-comp>



                </div>
            </div>

            <div class="m3">
                <!--최근 배팅내역-->
                <leisure-lately-bet-list-comp
                        ref="latelyBetList"
                        :kind="kind"></leisure-lately-bet-list-comp>
            </div>


        </div>
    </div>
</template>

<script>
    import SubTitle from "../../../components/SubTitle";
    import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
    import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
    import leisureConst from "../../../common/leisureConst";
    import sportsConst from "../../../common/sportsConst";
    import {getLeisureGameAttributeConfig, getOnBetLeisureGames} from "../../../network/leisureRequest";
    import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
    import {leisureGameMixin} from "../../../common/mixin";
    import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
    import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
    import LeisureGameLinks from "../LeisureGameLinks";
    import LeisureGameIframView from "../LeisureGameIframView";
    import LeisureBet365DogLinks from "../LeisureBet365DogLinks";
    import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

    export default {

        name: "Bet365HorseRace2",
        mixins: [leisureGameMixin],
        components: {
            LeisureBet365DogLinks,
            LeisureGameIframView,
            LeisureGameLinks,
            LeisureGameView,
            LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
        },
        data() {
            return {
                kind: leisureConst.LEISURE_KIND_BET365_HORCERACE_2, //브리타니아 웨이
            }
        },
        methods: {
            initLeisureGameArttributeConfig() {
                getLeisureGameAttributeConfig().then(res => {
                    if (res.data.success) {
                        this.attrConfig = res.data.data
                        //배당설정
                        this.horseraceConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_HORCERACE_2_NORMAL)
                        })
                    }
                })
            }
        },
        created() {
            this.initKindConfig(this.kind)
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_BET365);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
        },
    }
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  .mini_game .m2{
    --margin-top: 60px;
  }
  .mini_game .mini_fram{
    min-height: 210px!important;
  }
}
</style>